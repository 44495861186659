
























import Vue from 'vue'
import Component from 'vue-class-component'
import SygniDroparea from "@/components/inputs/SygniDroparea.vue";
import SygniRoundedButton from "@/components/buttons/SygniRoundedButton.vue";
import {BProgress} from "bootstrap-vue";
import SygniInputError from "@/components/inputs/SygniInputError.vue";
import { Prop } from "vue-property-decorator";

@Component({
  components: {SygniInputError, SygniRoundedButton, SygniDroparea, BProgress},
})
export default class SygniLogoUploader extends Vue {
  @Prop({default: 'Upload logo'}) title: string;
  @Prop({default: 'default'}) type: string;
  @Prop() tempLogo: string;
  @Prop() note: string;

  tempImageUrl: string = '';

  openFileSelector(): void {
    (this.$refs[`fileInput`] as HTMLInputElement).click();
  }

  addFile(file: File): void {
    this.tempImageUrl = URL.createObjectURL(file);
    this.$emit('input', file);
  }

  deleteLogo(): void {
    this.tempImageUrl = '';
    (this.$refs['fileInput'] as HTMLInputElement).value = '';
    this.$emit('delete');
  }

  get isProfileType() {
    return this.type == 'profile' && !this.tempImageUrl;
  }

  mounted() {
    if(this.tempLogo) {
      this.tempImageUrl = this.tempLogo;
    }
  }
}
